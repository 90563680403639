import AnuncioDadosMixin from './AnuncioDadosMixin'

export default {
  name: 'CardPainel',
  mixins: [AnuncioDadosMixin],
  props: {
    dados: {
      foto: null,
      tipo: null,
      situacao: null,
      dadosSimulacao: {
        valor_mercado: null,
        valorParcela: null,
        parcelas: null,
        situacao_id: null
      },
      anuncio: {
        foto: null,
        cor_objeto: {
          cor_nome: null
        },
        cambio_objeto: {
          cambio_nome: null
        },
        combustivel_objeto: {
          combustivel_nome: null
        },
        bem: {
          versao: {
            nome: null,
            modelo: {
              nome: null,
              marca: {
                id: null,
                nome: null
              }
            }
          }
        }
      }
    }
  },
  computed: {
  },
  methods: {
    irProposta (item) {
      const anuncioId = item && item.anuncio_id ? item.anuncio_id : 0
      const propostaid = item ? item.id : 0

      this.$router.push({ name: 'painel-proposta-editar', params: { id: anuncioId, propostaid } })
    }
  },
  filters: {
  }
}
