import Carregando from 'src/components/CarregandoGeral/Carregando.vue'
import CardPainel from 'src/components/Card/CardPainel.vue'
import AgendamentoModel from 'src/model/usuario/AgendamentoModel'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import vTopFilters from 'src/core/filters/vTopFilters'
import { LocalStorage } from 'quasar'
import DpGridStore from 'src/store/dp-grid/'
import CrederePropostaModel from 'src/model/usuario/CrederePropostaModel'
import VeiculosEntradaModel from 'src/model/veiculo/VeiculosEntradaModel'

export default {
  name: 'PainelInicio',
  components: { Carregando, CardPainel },
  mixins: [vTopFilters, NotificacaoMixin],
  data () {
    return {
      modelAgendamento: new AgendamentoModel(),
      modelCrederePropostas: new CrederePropostaModel(),
      modelVeiculosEntrada: new VeiculosEntradaModel(),
      buscando: true,
      tabpainel: 'minhaspropostas',
      tabpropostas: 'ativas',
      carregandopropostas: true,
      veiculosDisponiveisEntrada: [],
      statusClass: [
        { id: 1, class: 'text-grey-7' },
        { id: 2, class: 'text-amber-9' },
        { id: 3, class: 'text-positive' },
        { id: 4, class: 'text-red-9' }
      ]
    }
  },
  meta () {
    return {
      title: 'Painel Proposta - Netcarros'
    }
  },
  computed: {
    propostas: {
      get () {
        let propostas = this.$store.getters['dp-grid/getDados'].propostas ?? []
        let testeDrive = this.$store.getters['dp-grid/getDados'].testeDrive ?? []
        let delivery = this.$store.getters['dp-grid/getDados'].delivery ?? []
        let videochamada = this.$store.getters['dp-grid/getDados'].videochamada ?? []
        propostas = propostas.concat(testeDrive).concat(delivery).concat(videochamada)
        return propostas
      }
    },
    usuarioSelecionado: {
      get () {
        return LocalStorage.has('usuarioSelecionado') && LocalStorage.getItem('usuarioSelecionado')
          ? LocalStorage.getItem('usuarioSelecionado')
          : this.logado
      }
    },
    logado: {
      get () {
        return this.$store.getters['usuario-login/get'] ? this.$store.getters['usuario-login/get'] : LocalStorage.getItem('logado')
      }
    }
  },
  beforeCreate () {
    if (!this.$store.hasModule('dp-grid')) this.$store.registerModule('dp-grid', DpGridStore)
    let veiculosEntradas = new VeiculosEntradaModel()
    veiculosEntradas.getMeusVeiculos().then(res => {
      this.veiculosDisponiveisEntrada = res.dados.map((item) => {
        let veiculo = item
        veiculo.marca_nome = item.versao.modelo.marca.nome
        veiculo.label = item.versao.nome + ' - ' + item.placa
        return veiculo
      })
    }).catch(e => {
      console.log(e)
    })
  },
  mounted () {
    if (this.logado && this.logado.id !== null && this.logado.roles && this.logado.roles.find(e => e.name === 'Vendedor')) {
      if (LocalStorage.has('usuarioSelecionado')) {
        this.usuarioSelecionado = LocalStorage.getItem('usuarioSelecionado')
      }
    }

    this.$nextTick(() => {
      if (this.logado && this.logado.id && this.logado.id !== null) {
        this.buscarTodosAgendamentos()
        this.getPropostas()
      } else {
        this.carregandopropostas = false
        this.notificacao('aviso', 'Você está deslogado!', 6000)
        setTimeout(() => {
          this.notificacao('aviso', 'Você não está logado para ver seus agendamentos!', 8000)
        })
      }
    })
    setTimeout(() => {
      this.carregandopropostas = false
    }, 3000)
  },
  methods: {
    buscarTodosAgendamentos () {
      const params = { buscaGrid: { usuario_id: this.usuarioSelecionado.id }, searchJoin: 'and', limit: 1000 }

      this.modelAgendamento.include = 'anuncio.bem'
      this.modelAgendamento.getListagem({ params }).then((res) => {
        this.buscando = false

        if (res && res.dados) {
          // Foto Veiculo
          res.dados.map((item) => {
            if (item && item && item.anuncio && item.anuncio.bem && item.anuncio.bem.bem_arquivos && item.anuncio.bem.bem_arquivos.length > 0) {
              item.foto = item.anuncio.bem.bem_arquivos[0].arquivo_url_completa
            }
            return item
          })
          /* */

          const delivery = res.dados.filter(e => e.tipo === 'Delivery' && e.anuncio && e.anuncio.bem)
          const videochamada = res.dados.filter(e => e.tipo === 'Videochamada' && e.anuncio && e.anuncio.bem)
          const testeDrive = res.dados.filter(e => e.tipo === 'Teste Drive' && e.anuncio && e.anuncio.bem)

          this.$store.commit('dp-grid/putDados', { recurso: 'delivery', payload: delivery })
          this.$store.commit('dp-grid/putDados', { recurso: 'videochamada', payload: videochamada })
          this.$store.commit('dp-grid/putDados', { recurso: 'testeDrive', payload: testeDrive })
        }
      }).catch(error => {
        this.notificacao('erro', error.msg)
        console.error(error)
        this.buscando = false
      })
    },

    getPropostas () {
      const params = { buscaGrid: { usuario_id: this.usuarioSelecionado.id }, searchJoin: 'and', limit: 1000 }

      this.modelCrederePropostas.getListagem({ params }).then((res) => {
        this.buscando = false
        if (res && res.dados) {
          const propostas = res.dados.filter(e => (e && e.anuncio_id > 0) || (e.simulacao && e.simulacao.anuncio_id > 0))

          propostas.map((item) => {
            // Foto Veiculo

            if (item && item.anuncio && item.anuncio.bem && item.anuncio.bem.bem_arquivos && item.anuncio.bem.bem_arquivos.length > 0) {
              item.foto = item.anuncio.bem.bem_arquivos[0].arquivo_url_completa
            } else if (item && item.simulacao && item.simulacao.anuncio && item.simulacao.anuncio.bem && item.simulacao.anuncio.bem.bem_arquivos && item.simulacao.anuncio.bem.bem_arquivos.length > 0) {
              item.foto = item.simulacao.anuncio.bem.bem_arquivos[0].arquivo_url_completa
            }
            /* */

            // Dados Simulação credere
            item.dadosSimulacao = {}

            item.dadosRequestLog = JSON.parse(item.request_log ? item.request_log.response : null)
            let simulacao = null
            if (item.dadosRequestLog) simulacao = item.dadosRequestLog.complete_proposal ?? item.dadosRequestLog.proposal

            if (simulacao) {
              const { proposal_attempt: tentativa } = simulacao
              if (tentativa) {
                let { bank: banco, financed_amount_in_cents: valor, term_financing: parcelas, quota_in_cents: parcela, created_at: data } = tentativa
                valor = (valor / 100)
                item.dadosSimulacao = {
                  valor,
                  banco: banco.name,
                  parcelas,
                  valorParcela: (parcela.toFixed(2) / 100),
                  data
                }
              }
            }
            /* Fim - Dados Simulação credere */

            // Preenchendo os dados
            if (!item.dadosSimulacao.data) item.dadosSimulacao.data = item.created_at
            if (!item.dadosSimulacao.parcelas && item.banco_id) item.dadosSimulacao.parcelas = item.parcela
            if (!item.dadosSimulacao.valorParcela && item.banco_id) item.dadosSimulacao.valorParcela = item.valor_parcela
            if (!item.dadosSimulacao.valor && item.banco_id) item.dadosSimulacao.valor = item.valor_total
            item.dadosSimulacao.situacao = item.situacao_credere_proposta ? item.situacao_credere_proposta.nome : ''
            item.dadosSimulacao.situacao_id = item.situacao_credere_proposta_id

            if (!item.anuncio_id && !item.anuncio && !item.banco_id && item.simulacao && item.simulacao.anuncio && item.simulacao.anuncio.bem) {
              item.anuncio = item.simulacao.anuncio
            }
            /* */

            return item
          })

          this.$store.commit('dp-grid/putDados', { recurso: 'propostas', payload: propostas })
          this.carregandopropostas = false
        } else {
          this.carregandopropostas = false
        }
      }).catch(error => {
        this.notificacao('erro', error.msg)
        console.error(error)
        this.buscando = false
        this.carregandopropostas = false
      })
    },

    irProposta (item) {
      const anuncioId = item && item.anuncio_id ? item.anuncio_id : 0
      const propostaid = item ? item.id : 0

      this.$router.push({ name: 'painel-proposta-editar', params: { id: anuncioId, propostaid } })
    },

    irVeiculo () {
      this.$router.push({ name: 'comprar' })
    },
    irVenda () {
      this.$router.push({ name: 'vender' })
    },
    getClassStatus (id) {
      return this.statusClass.find((item) => item.id === id)?.class ?? this.statusClass[0].class
    }
  }
}
