import { render, staticRenderFns } from "./Inicio.vue?vue&type=template&id=4133a1be&"
import script from "./Inicio.js?vue&type=script&lang=js&"
export * from "./Inicio.js?vue&type=script&lang=js&"
import style0 from "./Inicio.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QTabs,QTab,QTabPanels,QTabPanel,QSkeleton,QColor,QIcon});
